.header {
    position: relative;
    margin-left: -24px;
    margin-right: -24px;
}

.title {
    text-align: center;
    margin: 16px !important;
    width: calc(100% - 32px);
}

.close {
    position: absolute !important;
    top: 16px;
    right: 12px;
    color: black !important;
    background-color: #f7f7f7 !important;
}
