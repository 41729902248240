/*
    components: upcoming-lessons.scss
*/

.upcoming-lessons {
    overflow-y: auto;
    flex-grow: 1;

    &__title {
        position: sticky;
        top: 0;
        color: $color-text-tertiary;
        padding-bottom: calc(#{$unit} * 2);
        background-color: $background-color;
    }

    &:last-child{
      @media (max-width: $responsive-sm) {
        margin-bottom: 30px;
      }
    }
}
