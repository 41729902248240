.container {
    width: 100%;
    height: calc(100vw - 48px);
    position: relative;
    border: 2px dotted black;
    background-color: var(--grey-f7);
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}

.photoIcon {
    height: 40px;
    width: 40px;
}

.dragOverlay {
    z-index: 5;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: rgba(255, 153, 0, 0.2);
}

.title {
    font-size: 24px;
    font-weight: 400;
}

.cta {
    background-color: black;
    font-weight: 900;
    border-radius: 8px;
    padding: 8px 12px;
    font-size: 12px;
    color: white;
    margin-top: 16px;
    border: 2px solid black;

    &:hover {
        background-color: #333;
    }
}

@media screen and (min-width: 768px) {
    .container {
        width: 100%;
        max-width: 630px;
        max-height: 630px;
        height: 630px;
    }

    .photoIcon {
        height: 60px;
        width: 60px;
        margin-bottom: 16px;
    }

    .cta {
        font-size: 14px;
        padding: 12px 16px;
    }
}
