/*
    components: upload.scss
*/

.upload {
  position: relative;
  flex-grow: 1;
  height: 97px;
  border-radius: $border-rounded;
  border: 1px dashed #cecece;
  cursor: pointer;

  &__images {
    position: relative;
    overflow: hidden;
    border-radius: $border-rounded;
    width: 80px;
    height: 80px;
    border-radius: 1000px;
    padding: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: hsla(248, 86%, 69%, 0.05);

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      border-radius: 1000px;
    }
  }

  &__placeholder {
    width: 80px;
    height: 80px;
    background-color: hsla(248, 86%, 69%, 0.05);
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 1000px;
    padding: 5px;
    margin-right: 24px;

    &--background {
      background-color: $white;
      border-radius: 1000px;
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }

  &__text {
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
  }

  &__drag-overlay {
    z-index: 5;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: rgba(255, 153, 0, 0.2);
  }
}

.record-video-button {
  height: 97px;
  flex-grow: inherit;
}
