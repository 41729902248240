/*
    utils: overflow.scss;
*/

.overflow {
    &--auto {
        overflow: auto;
    }

    &--y{
       &--scroll{
        overflow-y: scroll;
       }
    }
}
