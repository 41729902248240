.container {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 8px;
}

.videoContainer {
    height: 224px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.buttons {
    width: 100%;
    display: grid;

    grid-template-columns: 1fr 1fr;
    gap: 16px;
    padding-top: 16px;
}

@media screen and (min-width: 768px) {
    .container {
        height: initial;
    }

    .videoContainer {
        height: initial;
    }
}
