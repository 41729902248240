.button {
    text-align: left;
}

.buttonContent {
    display: grid;
    grid-template-columns: 1fr auto;
    gap: 1rem;
    width: 100%;
    align-items: center;
    padding: 8px;
    font-weight: 400;
}

.image {
    height: 36px;
    width: 36px;
    object-fit: contain;
}

.title {
    font-size: 18px !important;
    line-height: 22px !important;
}

.subtitle {
    font-size: 15px;
    line-height: 20px;
    color: var(--color-text-secondary);
}

@media screen and (min-width: 768px) {
    .buttonContent {
        padding: 20px 30px;
    }

    .image {
        height: 42px;
        width: 42px;
    }

    .title {
        font-size: 25px;
        line-height: 30px;
    }

    .subtitle {
        font-size: 20px;
        line-height: 25px;
    }
}
