.container {
    position: relative;
    padding: 0px 24px 16px 24px;
}

.form {
    position: relative;
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 16px;
}

.title {
    font-size: 25px;
    line-height: 30px;
    font-weight: 700;
}

.subtitle {
    font-size: 15px !important;
    line-height: 25px !important;
    font-weight: 400 !important;
    padding-bottom: 8px;
}

@media screen and (min-width: 768px) {
    .container {
        padding: 0px 24px 16px 24px;
    }
    .form {
        max-width: 630px;
        margin: 0px auto;
    }

    .centered {
        margin-top: 20vh;
        margin-bottom: 88px;
    }

    .title {
        font-size: 35px;
        line-height: 42px;
        font-weight: 700;
    }

    .subtitle {
        font-size: 20px !important;
        line-height: 25px !important;
        font-weight: 400 !important;
    }
}
