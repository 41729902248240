/*
    utils: flex
*/

.flex {
  display: flex;

  &--inline {
    display: inline-flex;
  }

  &--primary {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  &--col {
    flex-direction: column;
  }

  &--row {
    flex-direction: row;
  }

  &--center {
    align-items: center;
  }

  &--end {
    align-items: flex-end;
  }

  &--start {
    align-items: flex-start;
  }

  &--baseline {
    align-items: baseline;
  }

  &--jc {
    &--space-between {
      justify-content: space-between;
    }

    &--center {
      justify-content: center;
    }

    &--end {
      justify-content: flex-end;
    }

    &--start {
      justify-content: flex-start;
    }

    &--space-around {
      justify-content: space-around;
    }

    &--space-evenly {
      justify-content: space-evenly;
    }
  }

  &--gap-5 {
    gap: 5px;
  }

  &--gap-10 {
    gap: 10px;
  }

  &--gap-20 {
    gap: 20px;
  }

  &--gap-30 {
    gap: 30px;
  }

  &--gap-40 {
    gap: 40px;
  }

  &--gap-100 {
    gap: 100px
  }

  &--gap-200 {
    gap: 200px
  }

  &--grow {
    flex-grow: 1;
  }

  &--shrink {
    flex-shrink: 0;
  }

  &--wrap {
    flex-wrap: wrap;
  }

  &--ai {
    &--center {
      align-items: center;
    }

    &--baseline {
      align-items: baseline;
    }

    &--start {
      align-items: start;
    }

    &--end {
      align-items: end;
    }
  }
}

.flex-gap {
  @each $modifier, $classModifier in $modifiers {
    &#{$modifier} {
      @each $i in $scale {
        &-#{$i} {
          gap#{$classModifier}: calc(#{$unit} * #{$i});
        }
      }
    }
  }
}

.z-index-5 {
  z-index: 5;
}

.z-index-10 {
  z-index: 10;
}

.z-index-1000 {
  z-index: 1000;
}

.flex--1 {
  flex: 1 1 0; /* or flex: 1; */
}
