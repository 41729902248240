/*
    components: tooltip.scss;
*/

.tooltip {
    &--password {
        z-index: 1;
        position: absolute;
        left: 0;
        bottom: calc(100% + 12px);

        width: 100%;
        max-width: 448px;
        padding: calc(#{$unit} * 4);
        border: 1px solid $color-primary-light;
        border-radius: $border-rounded;

        background-color: $white;
        opacity: 0;
        visibility: hidden;

        &.active {
            opacity: 1;
            visibility: visible;
        }

        &:after {
            content: '';
            position: absolute;
            bottom: -11px;
            left: 30px;

            width: 20px;
            height: 20px;
            border: 1px solid $color-primary-light;

            transform: rotate3d(0, 0, 1, 45deg);
            background-color: $white;
        }
        &:before {
            z-index: 3;
            content: '';
            position: absolute;
            bottom: 0px;
            left: 20px;

            width: 40px;
            height: 17px;
            background-color: $white;
        }
        &--pos-top {
            &.tooltip--password {
                width: 410px;
                bottom: unset;
                top: calc(100% + 12px);
                &::after {
                    bottom: unset;
                    top: -11px;
                }
                &::before {
                    bottom: unset;
                    top: 0px;
                }
            }
        }
    }

    &--text {
        z-index: 1;
        position: absolute;
        bottom: 40px;
        right: -100px;
        overflow-y: auto;

        @media (max-width: $responsive-sm) {
          right: -140px;
        }

        max-height: 160px;
        display: flex;
        //width: 250px;

        color: $color-primary;
        padding: 16px 16px 0 16px;
        border: 1px solid $color-primary;
        border-radius: $border-rounded;

        //some background colors hide scrollbar !!!
        background-color: $white;
        opacity: 0;
        visibility: hidden;

        span {
            display: inline-block;
            background-color: hsla(248, 86%, 69%, 0.1);
            padding: 4px 16px;
            border-radius: 5px;
            margin: 0 8px 16px 0;
        }

        &.active {
            opacity: 1;
            visibility: visible;
        }
    }

    &--phone {
        position: absolute;
        width: 370px;
        left: 0;
        top: -60px;
        z-index: 1;
        opacity: 0;
        visibility: hidden;
        background-color: $white;
        font-size: 14px;
        font-weight: 400;

        border: 1px solid $color-primary;
        border-radius: $border-rounded;
        padding: 16px;

        &::before {
            content: '';
            width: 10px;
            height: 1px;
            background-color: $color-primary;
            position: absolute;
            bottom: -5px;
            left: 48%;
            transform: rotate(45deg);
        }

        &::after {
            content: '';
            width: 10px;
            height: 1px;
            background-color: $color-primary;
            position: absolute;
            bottom: -5px;
            left: 50%;
            transform: rotate(-45deg);
        }

        &.active {
            opacity: 1;
            visibility: visible;
        }
    }
}
