/*
    components: events.scss
*/

.event-passed{
  background: lightgray !important;
}

.event-in-reschedule{
  background: lighten($color-warning, 10%) !important;
}

.event {
    position: absolute;
    width: 100%;
    height: 83%;
    top: 0;
    left: 0;

    display: flex;
    align-items: center;
    padding: $unit;
    border: 1px solid #7e6cf2;

    color: #7e6cf2;
    background-color: $white;

    &--unavailable {
        position: absolute;
        background: repeating-linear-gradient(45deg, #f8f7fe, #f8f7fe 10px, #f2f1fe 10px, #f2f1fe 20px);
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        height: 100%;
        cursor: not-allowed;
    }
    &--unavailable-custom {
      position: absolute;
      //border-top:1px solid #88bfff;
      background: repeating-linear-gradient(45deg, #f8f7fe, #f8f7fe 10px, #f2f1fe 10px, #f2f1fe 20px);
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      display: flex;
      align-items: center;
      justify-content: center;
      height: 100%;
    }

  &--unavailable-min-time {
    position: absolute;
    background: repeating-linear-gradient(45deg, #f8f7fe, #f8f7fe 10px, #f2f1fe 10px, #f2f1fe 20px);
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
  }

  &--unavailable-custom:hover {
    border-top:2px solid #c9e2ff;
  }
    &--pending {
        background: #7e6cf2;
        color: $white;
        border: none;
    }
}
