/*
    base: scrollbar.scss
*/

/* width */
::-webkit-scrollbar {
    width: 1px;
    height: 4px;
}

/* Track */
::-webkit-scrollbar-track {
    background: transparent;
}

::-webkit-scrollbar-corner {
    background: #666;
}

/* Handle */
::-webkit-scrollbar-thumb {
    background: #666;
    border-radius: 6px;
    &:hover {
        background: #555;
    }
    &:active {
        background: #444;
    }
}

//Firefox scrollbars
* {
    scrollbar-width: thin;
}
