/*
    components:chat
*/

.chat {
  //Conversation aside
  &__conversation {
    display: flex;
    padding: calc($unit * 3) calc($unit * 4);

    &__active {
      background-color: #ededed;
      position: relative;
    }

    &__active:before {
      background-color: #7e6cf2;
      border-radius: 0 4px 4px 0;
      content: "";
      height: 100%;
      left: 0px;
      opacity: 1;
      position: absolute;
      top: 50%;
      -webkit-transform: translateY(-50%);
      transform: translateY(-50%);
      width: 4px;
      z-index: 2;
    }

    &__avatar {
      flex-shrink: 0;
      border-radius: $border-circle;
      width: 60px;
      height: 60px;
      object-fit: cover;
      background-color: $grey-ed;

      &--small {
        width: 40px;
        height: 40px;
      }
    }

    &__dot {
      width: 10px;
      height: 10px;
      border-radius: $border-circle;
      background: #7e6cf2;
      margin: 0 auto;
    }

    & > div:last-of-type > .type--sm {
      position: absolute;
      top: 0;
      width: 100%;
      text-align: right;
      text-transform: capitalize;
    }
  }

  &__spacer {
    height: 1px;
    background-color: $grey-ed;
    margin-top: calc($unit * 3);
    //add negative margins
  }

  &__messages-wrapper {
    overflow-y: auto;
  }

  &__message {
    display: flex;

    &--other {
      flex-direction: row;
    }

    &--logged {
      flex-direction: row-reverse;
    }

    &__item {
      border-radius: calc($unit * 2);
      padding: calc($unit * 2) calc($unit * 6);
      margin-top: 6px;
      width: fit-content;
      display: flex;
      flex-direction: column;

      &--other {
        color: $black;
        background-color: $grey-ed;
        margin-left: calc($unit * 4);
      }

      &--logged {
        background-color: $color-primary;
        color: $white;
        margin-right: calc($unit * 0);
        text-align: left;
      }

      &--logged a {
        color: $color-primary;
        margin-right: calc($unit * 0);
        text-align: left;
      }

      &__end {
        margin-right: 16px;
      }
    }

    &__margin-top {
      margin-top: calc($unit * 6);
    }

    &__margin-left {
      margin-left: calc($unit * 10);
    }

    &__margin-right {
      margin-right: calc($unit * 10);
    }
  }

  &__input {
    display: flex;
    align-items: center;
    padding: calc($unit * 5) calc($unit * 6);
  }
}

.message-full-width {
  width: 100%;
}

form.chat-file-send-form {
  width: 100%;
  height: 100%;
  padding-bottom: 10px;
  display: flex;
  justify-content: center;
  align-items: end;
}

.input-file-relative {
  position: relative;
}

.input-file-hidden {
  position: absolute;
  display: flex;
  width: 100%;
  height: 100%;
  opacity: 0;
  top: 0;
  left: 0;
  z-index: 2;
  pointer-events: all;
  cursor: pointer;
}

.aside-conversation-message {
  pointer-events: none;
}

.chat-file-message-send {
  display: flex;
  position: relative;
  margin-left: calc($unit * 20);
  margin-right: auto;
  margin-top: 8px;
  margin-bottom: 8px;
  padding: 8px 16px;
  background: linear-gradient(226.69deg, rgba(127, 95, 211, 0.9) 4.52%, #5c3ee8 94.64%);
  color: #fff;
  border-radius: calc($unit * 4);

  button {
    margin-left: 8px;
    outline: none;
    background: transparent;
    font-weight: bold;
    color: white;
    border: none;
    cursor: pointer;
  }
}

.chat-file-outline {
  background: transparent;
  border: 1px solid $color-primary;
  border-radius: calc($unit * 4);
  display: inline-flex;
  padding-left: calc($unit * 2);
}

.chat-file-outline .chat-file-icon {
  margin-right: calc($unit * 2);
}

.chat-file-icon {
  width: 24px;
  height: 24px;
  background: $color-primary;
}

.chat-file-message-send button i {
  background-color: white;
  width: 24px;
  height: 24px;
  display: flex;
}

.chat-single-conversation-link {
  display: flex;
}

.chat-load-more {
  width: 80px;
  height: 80px;
  display: flex;
  margin: auto;
  background-color: #ddd;
}

.close-button-popup {
  position: absolute;
  top: -8px;
  right: -8px;
  width: 24px;
  height: 24px;
  z-index: 2;
  background: linear-gradient(226.69deg, rgba(127, 95, 211, 0.9) 4.52%, #5c3ee8 94.64%) !important;
  border-radius: 50%;
}

.chat-file-message-send button.close-button-popup i {
  width: 16px;
  height: 16px;
  margin-left: -2px;
}

.chat-message-timestamp-receiver {
  font-size: 10px;
  color: #888;
}

.chat-message-timestamp-sender {
  font-size: 10px;
  color: #dfdfdf;
  text-align: right;
}

.chat__overlay__free__consultation {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  pointer-events: all;
  z-index: 999;
  background: rgba(0, 0, 0, 0.33);
}

.free-consultation-btn-pressed {
  z-index: 1000;
}

.free-consultation-btn {
  display: flex;
  justify-content: space-around;
  align-items: center;
  line-height: 1.5;
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.chat-load-more-small {
  width: 24px;
  height: 24px;
  display: flex;
  background: white;
  animation: spin 1s linear infinite;
}

.button-group-chat-header {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
}

.button-group-chat-header > * {
  margin: auto 10px;
}

.video-chat-wrapper {

}

.border-hover:hover {
  border: lightslategray solid 1px;
  border-radius: 5px;
  cursor: pointer;
  //box-shadow: black ;
}

.primary-color {
  color: $color-primary;
}

.disabled-color {
  color: $grey-bb;
}

.primary-color-light {
  color: $color-primary-light;
}

.align-self-start {
  align-self: start;
}

.align-self-end {
  align-self: end;
}

.align-self-center {
  align-self: center;
}

.file-message-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-end;
  color: black;
}

.message-container {
  display: flex;
  justify-content: flex-start;
}

.mx-1 {
  margin-left: 0.25rem;
  margin-right: 0.25rem;
}

.mx-2 {
  margin-left: 0.5rem;
  margin-right: 0.5rem;
}

.file-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  flex-wrap: wrap;
}

.badge-pop-up {
  transition: transform 0.2s ease-in-out;
}

.badge-pop-up.active {
  transform: scale(1.3);
}

@keyframes badgePulseAnimation {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.3);
  }
  100% {
    transform: scale(1);
  }
}

.badge-pulse {
  animation: badgePulseAnimation 0.6s ease-in-out 2; /* 2 indicates the number of times it will pulse */
}

.chat-circle-message-count-container {
  position: relative;
  margin-top: 1.5em;
  width: 24px;
  height: 24px;
}

.chat-circle-message-count-circle {
  background-color: #7e6cf2;
  border-radius: 50%;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.chat-circle-message-count-number {
  color: white;
  font-weight: bold;
  font-size: 12px;
}

.chat-wrap {
  //width: fit-content;
  max-width: 400px; /* Adjust this value as needed */
  @media (max-width: $responsive-sm) {
    max-width: 180px;
  }
  word-wrap: break-word;
  white-space: pre-wrap;
  overflow-wrap: break-word;
}
