.container {
    margin: 0px auto;
    overflow: hidden;
    max-width: 100%;
}

.priceRow {
    display: flex;
    flex-direction: row;
    font-weight: 900;
    font-size: 75px;
    line-height: 90px !important;
    border-bottom: 2px solid var(--grey-d8);
}

.currency {
    width: min-content;
}

.input {
    border: none;
    width: 180px !important;
    line-height: 75px !important;
    font-size: 75px !important;
    background-color: transparent;
    font-weight: 900;
    padding-left: 0px !important;

    &:focus,
    &:focus-visible {
        outline: none;
    }
    height: 90px;
}

.breakdown {
    width: fit-content;
    margin: 0 auto;
    min-width: 200px;
}

.breakdownContainer {
    border: 2px solid black;
    border-radius: 8px;
    padding: 12px;
    display: flex;
    flex-direction: column;
    gap: 8px;
    margin-bottom: 16px;
}

.paidContainer {
    border: 2px solid var(--grey-d8);
    border-radius: 8px;
    padding: 12px;
    margin-bottom: 16px;
}

.row {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    gap: 32px;
}

.expandButton {
    border: none;
    background-color: transparent;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    margin: 0 auto;
    color: var(--color-text-secondary);
    cursor: pointer;
}

.learnMoreContainer {
    position: fixed;
    bottom: 92px;
    left: 50%;
    transform: translateX(-50%);
    display: flex;
    flex-direction: column;
    gap: 8px;
    justify-content: center;
    align-items: center;
}

.learnMoreButton {
    background-color: transparent;
    border: none;
    color: var(--color-text-secondary);
    cursor: pointer;
    text-decoration: underline;
    width: fit-content;
}

.learnMore {
    color: var(--color-text-secondary);
    strong {
        color: var(--color-text-primary);
    }
    > * {
        margin-bottom: 12px;
    }
}

@media screen and (min-width: 768px) {
    .priceRow {
        font-size: 100px;
        line-height: 100px !important;
    }

    .input {
        width: 220px;
        font-size: 100px !important;
        line-height: 100px !important;
        height: 100px !important;
    }

    .expandButton {
        font-size: 20px;
        line-height: 25px;
    }

    .learnMoreContainer {
        bottom: 128px;
    }
}
