.consent {
    font-size: 15px;
    line-height: 20px;


}

@media screen and (min-width: 768px) {
    .consent {
        font-size: 20px;
        line-height: 25px;
    }
}
