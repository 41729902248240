.container--flex--space-around {
  display: flex;
  flex-direction: row;
  justify-content: space-around;

}

.container {
  &__fill-red {
    background-color: red;
  }
}

.lesson-row {
  display: flex;
  flex-direction: row;
  align-content: center;
  align-items: center;
  font-size: medium;
  width: 100%;
}

.container-dropdown {
  width: 100%;
  flex-direction: column;
}


.signup-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  background: white;
  padding: 2rem;
  margin: 0 auto;
  border-radius: 0.5rem;
  font-size: xxx-large;
  //width: 100%;
}

.level-card-container {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
}

.level-card-container > * {
  flex: 1;
}

.subject-card-container {
  display: flex;
  flex-wrap: wrap;
  gap: 5px;
  //justify-content: ; /* Centers the items */
  width: 100%;
}

.subject-card-container > * {
  flex: 0 0 calc(33.333% - 10px); /* Assuming you want three cards per row */
  margin: 2.5px; /* half of the 5px gap on all sides */
}

@media (min-width: 767px) {
  .signup-subject-container {
    width: 100%;
  }

  .mail-icon {
    width: 250px;
  }

  .confirm-mail-message {
    font-size: medium;
  }
}

@media (min-width: 767px) {
  .signup-subject-container {
    width: 30%;
  }

  .subject-card {
    font-size: medium;
  }

  .info-text {
    font-size: medium;
  }

  .btn-signup {
    font-size: medium;
  }
}

@media (max-width: 767px) {
  .margin-mobile {
    margin-bottom: 40px;
  }

  .mail-icon {
    width: 100px;
  }

  .confirm-mail-message {
    font-size: smaller;
  }

  .subject-card {
    font-size: 2vw;
  }

  .level-card {
    font-size: 2.5vw;
  }

  .info-text {
    font-size: small;
  }

  .btn-signup {
    font-size: small;
  }
}

.no-overflow {
  overflow-wrap: break-word;
  //white-space: nowrap;
  //overflow: hidden;
  text-overflow: fade;
  max-width: 100%;
}

.subject-border {
  border: 1px solid $color-primary;
}

.profile-btn-container {
  @media (max-width: $responsive-md) {
    margin-top: 4 * $unit;
  }
}

.subject-form-container {
  display: flex;
  flex-direction: row;

  @media (max-width: 767px) {
    flex-direction: column;
  }
}

.profile-preview-wrapper {
  width: 50%;
  //margin: 2 * $unit;
  @media (max-width: 767px) {
    width: 100%;
  }
}

.filters-container {
  gap: 5px;
}

.availability-container {
  display: flex;
  flex-direction: row;

  @media (max-width: $responsive-sm) {
    display: grid;
    grid-template-columns: 1fr 1fr;
  }
}

.availability-container-time {
  display: flex;
  flex-direction: row;

  @media (max-width: $responsive-sm) {
    display: flex;
    flex-direction: column;
  }
}

.notification-container {
  overflow: auto;

  @media (max-width: $responsive-sm) {
    overflow: unset;
  }
}

.price-sort-container {
  @media (max-width: $responsive-sm) {
    display: flex;
    flex-direction: column;
  }
}

.video-recorder-container {
  border-radius: 0.5em;
  position: absolute;
  transform: translate(-50%, -50%);
  top: 50%;
  left: 50%;
}

progress {
  /* Removes default appearance */
  -webkit-appearance: none;
  appearance: none;

  width: 100%; /* Full width */
  height: 20px; /* Adjust height */

  /* Customizable background color */
  background-color: #f3f3f3;
  border: 1px solid #ddd;
  border-radius: 5px;
  color: #4caf50; /* Color of the progress bar */
}

progress::-webkit-progress-bar {
  background-color: #f3f3f3;
  border-radius: 5px;
}

progress::-webkit-progress-value {
  background-color: #4caf50;
  border-radius: 5px;
}

progress::-moz-progress-bar {
  background-color: #4caf50;
  border-radius: 5px;
}

.outline-purple {
  outline: 2px solid $color-primary;
  -moz-outline-radius: 2px;
  border-radius: $border-rounded;
}

.image-border-radius {
  border-radius: $border-rounded;
}

.timezone-container {
  display: flex;
  flex-direction: row;

  @media(max-width: $responsive-md) {
    flex-direction: column;
  }
}

.select-width {
  width: 250px;

  @media (max-width: $responsive-md) {
    width: 200px;
  }
}

.bg-color-light {
  background-color: $color-primary-lighter;
  padding: 0 15px;
  border-radius: 15px;
}

.reviews-flex-container {
  justify-content: space-between;
  align-items: center;

  @media (max-width: $responsive-sm) {
    justify-content: center;
    gap: 20px;
  }
}


.timeslot-container {
  overflow: hidden;
  @media (max-width: $responsive-md) {
    max-height: calc(8 * 36px);
  }
  max-height: calc(8 * 37px); /* Adjust this height to show default 10 rows => 16margin +21text = 37*/
  transition: max-height 0.3s ease;
}

.timeslot-container.expanded {
  max-height: none; /* No limit when expanded */
}

.show-more-button {
  cursor: pointer;
  color: blue;
  text-align: center;
  margin-top: 10px;
}

.border-top-calendar {
  &--primary {
    border-top: 4px solid $color-primary;
  }

  &--secondary {
    border-top: 4px solid $grey-bb;
  }
}

.calendar-button-container {
  height: 23px;
  background: lightgray;
  border-radius: 4px;
  padding: 1px;
  box-sizing: content-box;
}

.booking-slots-container {
  @media (max-width: $responsive-md) {
    margin-bottom: calc(4 * $unit);

  }
}

.border-gray {
  border: 1.5px solid lightgray;
  border-radius: .35rem;
}

.border-fat {
  border: 2.5px solid lightgray;
}
