/*
    components: steps.scss
*/

.steps {
    position: relative;
    z-index: 2;

    &__item {
        display: flex;
        align-items: center;

        &__line {
            &::after {
                z-index: -1;
                content: '';
                position: absolute;
                height: 200px;
                border-left: 2px dashed $grey-ed;
                top: 10px;
                left: 18px;
            }

            &--sm {
                &::after {
                    z-index: -1;
                    content: '';
                    position: absolute;
                    height: 100px;
                    border-left: 2px dashed $grey-ed;
                    top: 10px;
                    left: 18px;
                }
            }
        }

        &--left {
            border-radius: 1000px;
            padding: 9px 15px;
            font-weight: 700;
            color: #202020;
            background-color: $grey-ed;

            &--completed {
                padding: 7px 7px;
            }

            &.active {
                font-weight: 700;
                color: $white;
                background-color: $color-primary;
            }
        }
    }

    &__title {
        font-size: 14px;
        font-weight: 400;

        &--primary {
            margin-bottom: 4px;
            color: #282828;
        }

        &--secondary {
            color: #202020;
            opacity: 0.5;
        }
    }
}
