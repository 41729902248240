/*
    components: reviews-list.scss;
*/

.reviews-list {
    &__item {
        display: flex;
        flex-direction: column;
        padding: calc(#{$unit} * 6) 0 calc(#{$unit} * 3) 10px ;
        border-bottom: 1px solid $grey-ed;

        @media (max-width: $responsive-xl) {
            flex-direction: column;
        }

        @media (max-width: $responsive-sm) {
          padding: calc(#{$unit} * 6) 0 calc(#{$unit} * 2) 10px;
        }
    }
}


.review-name-container{
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 20px;


  @media (max-width: $responsive-sm) {
    gap:10px;
  }
}
