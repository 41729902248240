/*
    components: field
*/

.signup-title {
    font-family: 'Poppins', sans-serif;
    font-weight: 500;
}

@media (max-width: 767px) {
    .sign-up-form-wrapper {
        font-size: small;
        width: 80%;
        max-width: 300px;
    }

    .field__w-60 {
        width: 100%;
    }

    .signup-title {
        font-size: 18px;
    }

    .password-tooltip {
        font-size: 10px;
    }
}

@media (min-width: 767px) {
    .sign-up-form-wrapper {
        width: 20%;
        min-width: 300px;
        font-size: medium;
    }
    .signup-title {
        font-size: 35px;
    }

    .password-tooltip {
        font-size: small;
    }
}

.sign-up-form-wrapper {
    //margin: 0 auto;
    text-align: center;
    flex-direction: column;
    //align-items: center;
    //width: 20%;
    //font-size: large;
    //background-color: red;
}

.react-tel-input .form-control {
    height: 57px !important; /* Adjusts the height of the input field */
}

.react-tel-input .flag-dropdown {
    height: 57px !important; /* Adjusts the height of the dropdown button */
}

.field {
    position: relative;
    margin-bottom: calc(#{$unit} * 1);

    &__w {
        &-30 {
            width: 30%;
        }

        &-40 {
            width: 40%;
        }

        &-50 {
            width: 50%;
        }

        &-60 {
            width: 60%;
        }

        &-70 {
            width: 70%;
        }

        &-fit-content {
            width: fit-content;
        }
    }

    &__label {
        display: block;
        margin-bottom: calc(#{$unit} * 2);

        font-size: 14px;
        color: $color-text-primary;
    }

    &__validation {
        color: $color-error;
        margin-top: calc(#{$unit} * 0.5);
        min-height: 15px;
        font-size: $type-sm;
        margin-bottom: calc(#{$unit} * 1);
    }

    &__file {
        width: 100%;
        position: relative;

        &__wrap {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            width: 100%;
            height: 100px;
            border: 1px solid $grey-c4;
            border-radius: 4px;
        }

        input[type='file'] {
            border-radius: 4px;
            position: absolute;
            width: 100%;
            height: 100%;
            top: 20px;
            left: 0;
            bottom: 0;
            right: 0;
            opacity: 0;
            cursor: pointer;
        }
    }
}
