.container {
    padding: 0px 24px 16px 24px;
    height: 100%;
}

.titleContainer {
    padding-bottom: 20px;
}

.title {
    font-size: 35px;
    line-height: 42px;
    font-weight: 700;
}

.pointsContainer {
}

.point {
    display: grid;
    grid-template-columns: auto 1fr auto;
    gap: 16px;
    padding: 16px 0px;
}

.pointNumber {
    font-weight: 400;
    font-size: 18px;
    line-height: 20px;
}

.pointBody {
}

.pointTitle {
    font-weight: 400;
    font-size: 18px;
    line-height: 20px;
    padding-bottom: 8px;
}

.pointDescription {
}

.pointImage {
    height: 84px;
    width: 84px;
    object-fit: contain;
}

@media (min-width: 769px) {
    .container {
        display: grid;
        grid-template-columns: 1fr 1fr;
        margin: 0 10%;
        gap: 10vw;
        min-height: calc(100vh - 160px);
    }

    .titleContainer {
        display: flex;
        flex-direction: column;
        justify-content: center;
        padding-bottom: initial;
    }

    .pointsContainer {
        display: flex;
        flex-direction: column;
        justify-content: center;
        max-width: 662px;
    }

    .title {
        font-weight: 700;
        font-size: 54px;
        line-height: 70px;
        max-width: 530px;
    }

    .point {
        padding: 20px 0px;
    }

    .pointTitle,
    .pointNumber {
        font-size: 24px;
        line-height: 20px;
    }

    .pointDescription {
        font-size: 20px !important;
        line-height: 20px !important;
        font-weight: 400;
    }

    .pointImage {
        height: 136px;
        width: 136px;
    }
}
