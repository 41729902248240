.container {
  display: flex;
  flex-direction: column;

  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  background-color: white;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  border-radius: 0px;
  height: 100%;
  overflow: hidden;

  &:focus-visible {
    outline: none;
  }

  @media (min-width: 768px) {
    width: 566px;
    max-width: 96%;
    max-height: 90%;
    border-radius: 8px;
    height: initial;

  }
}

.header {
  position: relative;
}

.title {
  text-align: center;
  margin: 16px !important;
  width: calc(100% - 32px);
}

.divider {
  height: 1px;
  width: 100%;
  background-color: #c4c4c4;
}

.close {
  position: absolute !important;
  top: 16px;
  right: 12px;
  color: black !important;
  background-color: #F7F7F7 !important;
}

.children {
  overflow-y: auto;
  max-height: 100%;
  display: grid;
  grid-template-columns: 1fr;
  padding: 24px;
  gap: 4px;
}
