/*
    components: button.scss
*/

.btn {
  --box-shadow-color: #{$black};
  position: relative;
  display: inline-block;
  appearance: none;
  border: none;
  border-radius: $border-rounded;
  cursor: pointer;
  transition: all 200ms ease-out;

  &:focus {
    //box-shadow: 0 0 2px 2px var(--box-shadow-color);
    outline: none;
  }

  &:disabled {
    opacity: 0.5;
    cursor: not-allowed;
    user-select: none;
  }

  //button styles
  &--primary {
    background-color: $color-primary;
    color: $white;
    transition: none !important;

    &:hover {
      background: linear-gradient(226.69deg, rgba(127, 95, 211, 0.9) 4.52%, #5c3ee8 94.64%);
    }

    &:active {
      background: #{darken($color-primary, 40%)};
    }

    &:focus {
      --box-shadow-color: #{darken($color-primary, 15%)};
    }

    &:disabled {
      background: $grey-d8 !important;
      color: $grey-30;
    }

    &--landing {
      padding: calc((#{$unit} * 2) + 2px) calc(#{$unit} * 4.5);
      font-size: 16px;
      border-radius: 8px;
    }

  }

  &--secondary {
    background-color: $grey-d8;
    background: $white;
    color: $color-primary-dark;
    outline: 1px solid $color-primary-dark;

    transition: none !important;

    &:hover {
      background: $grey-ed;
    }

    &:active {
      background-color: darken($grey-d8, 15%);
    }

    &:disabled {
      background: $grey-d8;
      color: $grey-30;
    }

    &:focus {
      --box-shadow-color: #{darken($grey-d8, 15%)};
    }
  }

  &--tertiary {
    background-color: transparent;
    color: $color-primary-dark;
    transition: none !important;

    @media (max-width: $responsive-sm) {
      width: calc(#{$unit} * 50);
    }

    &:hover {
      color: darken($color-primary-dark, 20%)
    }

    &:active {
      background-color: darken($grey-d8, 15%);
    }

    &:focus {
      --box-shadow-color: #{darken($grey-d8, 15%)};
    }
  }

  &--success {
    background-color: $color-success;
    color: $white;

    &:hover {
      background-color: darken($color-success, 10%);
    }

    &:active {
      background-color: darken($color-success, 15%);
    }

    &:focus {
      --box-shadow-color: #{darken($color-success, 15%)};
    }
  }

  &--error {

    &--primary {
      background-color: $color-error;
      color: $white;

      &:hover {
        background-color: darken($color-error, 10%);
      }

      &:active {
        background-color: darken($color-error, 15%);
      }

      &:focus {
        --box-shadow-color: #{darken($color-error, 15%)};
      }
    }

    &--secondary {
      background: $white;
      color: $color-error;
      outline: 1px solid $color-error;

      &:hover {
        background: $grey-ed;
      }

      &:active {
        //background-color: darken($color-error, 15%);
        outline: 1px solid $color-error;
      }

    }

    &--tertiary {
      background: transparent;
      color: $color-error;
      //outline: 1px solid $color-error;
    }
  }

  &--ghost {
    background-color: transparent;
    color: $color-primary;
    border: 1px solid $color-primary;

    &--grey {
      border: 1px solid $grey-d8;

      &:hover {
        border: 1px solid $color-primary;
      }
    }

    &--error {
      border-color: $color-error;
      color: $color-error;
    }

    &--success {
      border-color: $color-success;
      color: $color-success;
    }

    &--bordered {
      border: 1px solid $color-primary;
      border-radius: $border-rounded;
    }

    &--landing {
      padding: calc(#{$unit} * 2) calc(#{$unit} * 4.5);
      font-size: 16px;
      border-radius: 8px;
      border-width: 2px;
      color: $color-primary;
    }

    &:focus {
      --box-shadow-color: #{darken($color-primary, 15%)};
    }
  }

  &--transparent-no-outline {
    background-color: transparent;
    color: gray;
    border: none;
    padding: 8px 16px;
    font-size: 14px;
    cursor: pointer
  }

  &--disabled {
    background-color: $color-primary;
    background: linear-gradient(226.69deg, rgb(61 60 64) 4.52%, rgb(140 139 143) 94.64%);
    color: $white;
    /*pointer-events: none;*/
    opacity: 0.7;
  }

  &--gray {
    background-color: $grey-c4;
    background: linear-gradient(226.69deg, rgb(61 60 64) 4.52%, rgb(140 139 143) 94.64%);
    color: $white;
    /*pointer-events: none;*/
    opacity: 0.7;
  }

  &--clear {
    background-color: transparent;
    color: $color-primary;
  }

  //button sizes
  &--sm {
    font-size: 14px;
    padding: calc(#{$unit} * 1) calc(#{$unit} * 4);
  }

  &--normal {
    font-size: 14px;
    padding: calc(#{$unit} * 1.5) calc(#{$unit} * 2);
  }

  &--base {
    font-size: 14px;
    padding: calc(#{$unit} * 3) calc(#{$unit} * 4.5);
  }

  &--md {
    font-size: 16px;
    padding: calc(#{$unit} * 5) calc(#{$unit} * 5);
  }

  &--lg {
    font-size: 14px;
    padding: calc(#{$unit} * 3) calc(#{$unit} * 20);
  }

  &--xl {
    font-size: 14px;
    padding: calc(#{$unit} * 3) calc(#{$unit} * 14);
  }
}

.btn-signup {
  font-family: 'Poppins', sans-serif;
  border-radius: 10px;
  font-weight: 500;
  font-size: medium;
}

.text-red {
  color: red;
}

.text-green {
  color: green;
}

.btn-active {
  border: 2px solid $color-primary; /* Blue border for the active button */
  color: $color-primary; /* Blue text for the active button */
}

.background-red {
  background-color: red;

}

.background-blue {
  background-color: blue;
}

.link-button {
  background: none;
  border: none;
  color: $color-primary;
  cursor: pointer;
  font-size: inherit;
  font-family: inherit;

  &:disabled {
    opacity: 0.5;
    cursor: not-allowed;
    user-select: none;
  }
}

.button-view-calendar {
  @media (max-width: $responsive-sm) {
    padding-left: 80px;
    padding-right: 80px;
  }
}

.border-button {
  border: 1px solid $color-primary;
}


.calendar-button {
  border: 1px solid lightgray;
  flex: 0;

  @media (max-width: $responsive-md) {
    font-size: 14px;
    padding: calc(#{$unit} * 1.5) calc(#{$unit} * 2);
  }
}
