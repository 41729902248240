/*
    pages: terms.scss;
*/

.terms {
    p {
        margin-bottom: calc(#{$unit} * 2);
    }
    h2 {
        margin-bottom: calc(#{$unit} * 6);
    }
    h3 {
        margin-bottom: calc(#{$unit} * 2);
        margin-top: calc(#{$unit} * 10);
    }
}
