/*
    components: tutor-list.scss
*/

$tutor-list-img-width: 176px;
$tutor-list-details-width: 264px;
$tutor-item-list-details-width: 220px;

.tutor-list {
  &__item {
    display: flex;
    border: 1px solid $grey-ed;
    border-radius: $border-rounded;
    margin-bottom: calc(#{$unit} * 4);
    padding: calc(#{$unit} * 6) calc(#{$unit} * 4) calc(#{$unit} * 6) calc(#{$unit} * 6);

    &:last-child {
      margin-bottom: 0;
    }

    &__img {
      width: $tutor-list-img-width;
      flex-shrink: 0;
      @media (max-width: $responsive-md) {
        //width: 0;
        width: 100px;
      }

      img {
        width: 154px;
        height: 154px;
        object-fit: cover;
        border-radius: $border-circle;
        border: 11px solid $color-primary-lighter;

        @media (max-width: $responsive-md) {
          width: 120px;
          height: 120px;
        }
      }
    }

    &__info {
      flex-grow: 1;
    }

    &__details {
      display: flex;
      flex-direction: column;
      width: $tutor-list-details-width;
      flex-shrink: 0;
      //border-left: 1px solid $grey-ed;
      padding-left: calc(#{$unit} * 6);
    }
  }

  &__no-results {
    display: flex;
    flex-direction: column;
    align-items: center;

    &__title {
      margin-bottom: calc($unit * 4);
      font-size: 40px;
    }

    &__subtitle {
      margin-bottom: calc($unit * 10);
      color: $color-text-primary;
    }
  }
}

.border-none {
  border: none;
}

.tutor-card {
  transition: transform 0.3s;
  border-radius: 10px;
  box-shadow: 0px 2px 15px -3px rgba(0, 0, 0, 0.1), 0px 10px 10px -3px rgba(0, 0, 0, 0.1), 0px 2px 10px -3px rgba(0, 0, 0, 0.1);
}

