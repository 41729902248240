/*
    utils: align
*/

.align {
  &--center {
    margin: 0 auto;
  }

  &--middle {
    vertical-align: middle;
  }
}

.text-align {
  &--center {
    text-align: center;
  }

  &--start {
    text-align: start;
  }

  &--end {
    text-align: end;
  }

  &--justify {
    text-align: justify;
  }
}
