.timeZoneSelect {
    width: 100%;
    margin: 16px 0px;
}

@media screen and (min-width: 768px) {
    .timeZoneSelect span:first-of-type {
        font-size: 20px;
    }
}
