/*
    pages: not-found.scss;
*/

.not-found {
    background-image: url(/assets/images/not-found.png);
    background-repeat: no-repeat;
    background-size: cover;
    background-position: 50% 50%;
    overflow: hidden;
    position: relative;
    height: 100vh;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    text-align: center;

    &__logo {
        display: block;
        width: 128px;

        margin-bottom: 80px;
    }

    &__title {
        font-size: 40px;
        color: $color-text-primary;
        margin-bottom: 40px;
    }

    &__subtitle {
        max-width: 280px;
        margin-bottom: 40px;

        font-size: 14px;
        color: $color-text-secondary;
    }

    &__img {
        position: absolute;
        width: 100%;
        height: 100%;
        z-index: -1;
    }
}
