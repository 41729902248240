/*
    components: lessons-list.scss
*/

.lessons-list {
    margin-right: calc(#{$unit} * 8);
    margin-left: calc(#{$unit} * 6);

    &__item {
        display: flex;
        align-items: center;
        border: 1px solid $grey-ed;
        border-radius: $border-rounded;

        margin-bottom: calc(#{$unit} * 4);
        padding: calc(#{$unit} * 4);
        box-shadow: 2px 4px 16px 0 rgba(0, 0, 0, 0.05);
        cursor: pointer;

        &.active {
            background-color: $color-primary;
            color: $white;

            .type--color--brand {
                color: $color-primary-light;
            }

            .lessons-list__item__img {
                border-color: rgba(255, 255, 255, 0.2);
            }
        }

        &__img {
            flex-shrink: 0;
            display: block;
            width: 52px;
            height: 52px;
            border-radius: $border-circle;
            border: 6px solid rgb(124, 106, 244, 10%);
            object-fit: cover;

            &__search-tutor{
              width: 100px;
              height: 100px;
            }
        }

        &__info {
            flex-grow: 1;
            margin: 0 calc(#{$unit} * 2);
        }
    }

    &__group {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding-bottom: calc(#{$unit} * 6);
        padding-top: calc(#{$unit} * 6);
        cursor: pointer;

        &__icon {
            transition: all 250ms ease-in-out;
        }

        &__name {
            text-transform: uppercase;
            letter-spacing: 1px;
            color: $grey-bb;
        }

        &__count {
            background-color: $color-primary-lighter;
            padding: calc(#{$unit} / 2) calc(#{$unit} * 4);
            color: $color-primary;
            border-radius: $border-rounded;
            margin-left: calc(#{$unit} * 2);
        }

        &__list {
            overflow: hidden;
            transition: all 250ms ease-in-out;
        }

        &--border {
            border-bottom: 1px solid $grey-ed;
            margin-left: -24px;
            margin-right: -24px;
        }
    }
}

.rotate {
    &--180 {
        transform: rotate3d(0, 0, 1, 180deg);
    }
    &--90 {
      transform: rotate3d(0, 0, 1, 90deg);
    }
}


.completed-lessons-list {
  margin-right: calc(#{$unit} * 8);
  margin-left: calc(#{$unit} * 6);

  &__item {
    display: flex;
    align-items: center;
    border: 1px solid $grey-ed;
    border-radius: $border-rounded;

    margin-bottom: calc(#{$unit} * 4);
    padding: calc(#{$unit} * 4);
    box-shadow: 2px 4px 16px 0 rgba(0, 0, 0, 0.05);

    &__info {
      flex-grow: 1;
      margin: 0 calc(#{$unit} * 2);
    }
  }
}

.completed-booking-info-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.completed-booking-info-container > div {
  flex: 0 0 calc(10% - 5px); /* Subtract 5px for spacing */
  margin-right: 10px;
}

.completed-booking-info-container > div:first-child {
  flex: 0 0 80%;
}

.completed-booking-pointer{
  cursor: pointer;
  transition: transform 0.3s ease;
}

.completed-booking-pointer:hover {
  transform: scale(1.2); /* Increase the size by 20% */
}

.arrow-icon {
  transition: transform 0.3s ease-in-out;
}

.rotated {
  transform: rotate(180deg);
}

.dropdown-content {
  max-height: 0;
  overflow: hidden;
  transition: max-height 0.3s ease-in-out, opacity 0.3s ease-in-out;
  opacity: 0;
}

.show-dropdown {
  max-height: 200px; /* This value can be adjusted based on your content size */
  overflow-y: auto;
  opacity: 1;
}
