.container {
    display: flex;
    flex-direction: column;
    width: 100%;
    position: relative;
}

.label {
    position: absolute;
    background-color: white;
    padding: 0px 8px;
    z-index: 10;
    left: 16px;
    top: 7px;
    font-size: 12px;
    color: var(--color-text-secondary);
}

.select > div {
    padding: 8px;
}
