.button {
    border: 3px solid #dcdce5 !important;
    margin-bottom: 4px !important;
    background-color: white !important;
    color: black !important;
}

.active {
    border: 3px solid black !important;
    background-color: #f7f7f7 !important;
}
