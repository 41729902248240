.container {
    position: relative;
    width: 100%;
    height: 100%;
    border: 2px dotted black;
    background-color: var(--grey-f7);
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.icon {
    height: 40px;
    width: 40px;
}

.dragOverlay {
    z-index: 5;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: rgba(255, 153, 0, 0.2);
}

.title {
    font-size: 24px;
    font-weight: 400;
}

.buttons {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 16px;
    padding: 16px;
}

.cta {
    font-weight: 900;
    border-radius: 8px;
    padding: 8px 12px;
    font-size: 12px;
    cursor: pointer;

    &.black {
        background-color: black;
        color: white;
        border: 2px solid black;

        &:hover {
            background-color: #333;
        }
    }

    &.white {
        background-color: transparent;
        border: 2px solid black;
        color: black;

        &:hover {
            background-color: white;
        }
    }
}

@media screen and (min-width: 768px) {
    .icon {
        height: 60px;
        width: 60px;
        margin-bottom: 16px;
    }

    .cta {
        font-size: 14px;
        padding: 12px 16px;
    }
}
