@import 'src/styles/base/vars.scss';

.icon {
    margin-top: 4px !important;
    height: 12px !important;
    width: 12px !important;
}

.red {
    color: $color-error;
}

.green {
    color: $color-success;
}
