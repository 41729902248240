/*
    pages: my-bookings.scss
*/

.my-bookings {
    padding: calc(#{$unit} * 6);
    display: flex;
    height: 100%;

    & > div {
        &:first-child {
            flex-grow: 1;
        }
        &:last-child {
            display: flex;
            flex-direction: column;
            flex-shrink: 0;
            width: 300px;
            margin-left: calc(#{$unit} * 6);
        }
    }

    &--unavailable {
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        cursor: not-allowed;

        background: repeating-linear-gradient(45deg, $grey-f7, $grey-f7 10px, $grey-fc 10px, $grey-fc 20px);
    }
}
