.introjs-tooltip{
  //background-color: red !important;
  min-width: 280px !important;
}

.introjs-button{
  background-color: rgba(126, 108, 242, 0.9) !important;
  color: white !important;
  text-shadow: none !important;
  box-shadow: none !important;
  border: none !important;
  font-family: Lato, sans-serif !important;
}

.introjs-bullets a {
  background-color: $color-primary-light !important;
}

.introjs-bullets a.active{
  background-color: $color-primary !important;
}

.introjs-helperLayer{
  box-shadow: rgba(126, 108, 242, 0.5) 0px 0px 1px 2px, rgba(33, 33, 33, 0.5) 0px 0px 0px 5000px !important;
}
