.layout {
    display: flex;
    gap: 16px;
    flex-direction: column;
    padding: 0px 24px 16px 24px;
}

.title {
    font-size: 25px;
    line-height: 30px;
    font-weight: 700;
    padding-bottom: 5px;
}

.subtitle {
    font-size: 15px;
    line-height: 20px;
    font-weight: 400;
    color: var(--color-text-secondary);
}

.container {
    display: grid;
    grid-template-columns: 1fr;
    gap: 16px;
}

.steps {
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 12px;
}

.previewButton {
    border: none;
    border-radius: 8px;
    font-weight: 400;
    font-size: 17px;
    background-color: white;
    padding: 5px 8px;

    &:hover {
        cursor: pointer;
        background-color: var(--grey-fc);
    }
}

.pointsTitle {
    font-weight: 700;
    font-size: 20px;
    line-height: 24px;
}

.iframeContainer {
    position: relative;
    width: calc(100% + 48px);
    margin: -24px;

    .iframe {
        border: none;
        z-index: 2;
    }

    .loader {
        position: absolute;
        top: 15px;
        left: 15px;
        width: calc(100% - 30px);
        display: flex;
        flex-direction: column;
        gap: 20px;

        z-index: 1;
    }

    .overlay {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: white;
        opacity: 0;
        z-index: 10;
    }
}

@media screen and (min-width: 768px) {
    .layout {
        max-width: 1000px;
        margin: 0 10%;
        min-height: calc(100vh - 160px);
        justify-content: center;
        margin: 0 auto;
    }

    .title {
        font-weight: 700;
        font-size: 50px;
        line-height: 60px;
        padding-bottom: 15px;
    }

    .subtitle {
        font-size: 20px;
        line-height: 25px;
        padding-bottom: 20px;
    }

    .container {
        grid-template-columns: 1fr 1fr;
        gap: 120px;
    }

    .steps {
        gap: 16px;
    }

    .pointsTitle {
        font-size: 25px;
        line-height: 30px;
    }
}
