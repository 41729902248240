.loader {
    display: flex;
    flex-direction: column;
    height: 100vh;
    width: 100%;
    justify-content: center;
    align-items: center;
    gap: 16px;
}

.inner {
    display: flex;
    width: 50%;
    min-height: 50vh;
    gap: 16px;
    flex-direction: column;
}

.header {
    display: grid;
    grid-template-columns: auto 1fr;
    gap: 16px;
}

@media screen and (min-width: 768px) {
    .loader {
    }
}
