/*
    components: background.scss
*/

.b {
    &--shadow {
        background-color: #00000085;
        position: absolute;
        top: 0;
        right: 0;
        left: 0;
        bottom: 0;
        z-index: 1;
        opacity: 1;
    }
}
