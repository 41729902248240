.link {
    display: grid;
    grid-template-columns: 1fr;
    color: inherit;
    cursor: pointer;
    border-radius: 8px;
    border: 1px solid var(--border-color);
    overflow: hidden;

    &:hover {
        background-color: var(--grey-f7);
    }

    &.withImage {
        grid-template-columns: 1fr 3fr;
    }
}

.image {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.body {
    display: flex;
    flex-direction: column;
    gap: 8px;
    padding: 12px;
}

.title {
    font-size: 15px;
}

.description {
    font-size: 15px;
    line-height: 20px;
}

@media screen and (min-width: 768px) {
    .body {
        padding: 16px;
        gap: 12px;
    }
    .title {
        font-size: 15px;
        line-height: 20px;
    }

    .description {
        font-size: 14px;
        line-height: 20px;
        color: var(--color-text-secondary);
    }
}
