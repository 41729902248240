
.scale-hover {
  transition: transform 0.8s ease;

  &--scale-105:hover {
    transform: scale(1.05);
  }

  &--scale-110:hover {
    transform: scale(1.1);
  }

  &--scale-115:hover {
    transform: scale(1.15);
  }

  &--scale-120:hover {
    transform: scale(1.2);
  }
}

.change-color-hover {
  transition: transform 0.8s ease;

  &--primary:hover {
    color: #7C6AF4;
  }
}

.underline-hover {
  position: relative;
  display: inline-block; /* Makes sure the width is only as wide as the text. */
}

.underline-hover::after {
  content: '';
  display: block;
  width: 0;
  height: 2px;
  background: #7C6AF4; /* Color of the underline. Adjust if needed. */
  transition: width 0.3s;
  position: absolute;
  bottom: 0;
  left: 0;
}

.underline-hover:hover::after {
  width: 100%;
}


.slide-in {
  animation: slideInFromBottom 0.5s forwards; /* Adjust 0.5s to control speed */
}

@keyframes slideInFromBottom {
  0% {
    transform: translateX(-100%);
    opacity: 0;
  }
  100% {
    transform: translateX(0);
    opacity: 1;
  }
}

.success-modal-animation {
  display: none;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: rgba(0, 0, 0, 0.8);
  padding: 20px;
  border-radius: 5px;
  text-align: center;
  animation: fadeIn 0.3s ease-in-out;
}

/* Keyframes for the fadeIn animation */
@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translate(-50%, -50%) scale(0.8);
  }
  to {
    opacity: 1;
    transform: translate(-50%, -50%) scale(1);
  }
}

@keyframes appearFromLeft {
  from {
    opacity: 0;
    transform: translateX(-50px);
  }
  to {
    opacity: 1;
    transform: translateX(0);
  }
}

.appear-from-left {
  animation: appearFromLeft 0.5s ease-out forwards;
}
