/*
    components: status.scss;
*/

.status {
    &--primary {
        display: inline-block;
        border-radius: $border-circle;
        width: calc(#{$unit} * 2);
        height: calc(#{$unit} * 2);
        background-color: $grey-c4;

        &--purple {
            background-color: $color-primary;
        }
        &--blue {
            background-color: $color-status-blue;
        }
        &--green {
            background-color: $color-status-green;
        }
    }
}
