/*
    components: table;
*/

.table {
  border-collapse: collapse;
  width: 100%;
  overflow-x: scroll;
  white-space: nowrap;

  th {
    text-align: left;
  }

  &--availability {
    max-width: 720px;
    border: 1px solid $grey-ed;

    @media (max-width: $responsive-md) {
      font-size: small;
    }

    td {
      position: relative;
      padding: calc(#{$unit} * 4);
      border: 1px solid $grey-ed;

      font-weight: bold;
      text-align: center;
      vertical-align: middle;
      cursor: pointer;

      @media (max-width: $responsive-sm) {
        padding: calc(#{$unit} * 2);
      }

    }

    &--check {
      background-color: $color-primary-bright;
    }

    &--close {
      background-color: $grey-fb;
    }
  }

  &--primary {
    border: 1px solid $grey-ed;

    td,
    th {
      border: 1px solid $grey-ed;
    }

    th {
      padding: calc(#{$unit} * 6);

      @media (max-width: $responsive-md) {
        padding: calc(#{$unit} * 2) calc(#{$unit} * 4);
      }
    }

    td {
      padding: calc(#{$unit} * 4) calc(#{$unit} * 6);

      @media (max-width: $responsive-md) {
        padding: calc(#{$unit} * 2) calc(#{$unit} * 4);
      }
    }

    &--zebra {
      tbody tr {
        &:nth-child(odd) {
          background-color: $color-primary-lighter;
        }
      }
    }
  }

  &--secondary {
    border: 1px solid $grey-ed;

    td,
    th {
      border: 1px solid $grey-ed;
    }

    th {
      padding: calc(#{$unit} * 6);
      background-color: $color-primary-bright;
    }

    td {
      padding: calc(#{$unit} * 4) calc(#{$unit} * 6);
    }

    tr {
      &:nth-child(even) {
        background-color: $grey-fb;
      }
    }
  }
}

.table--no-border td:nth-child(2) {
  border: none;
  padding: 0 0 0 15px;
}

.table--no-border td {
  border-spacing: 0;
  vertical-align: middle;
}

.table--no-border {
  border: none;
  border-collapse: separate;
  border-spacing: 5px; /* Adjust as needed */
}
