.bg {
  &__white {
    background: white;
  }

  &__green {
    background: #D8F8F2;
  }
}

body {
  background-color: #f8f4fe;
}
