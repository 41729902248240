/*
    components: tutor-list.scss
*/
.tutor--managment.modal {
  pointer-events: all;

  &.closed {
    opacity: 0;
    pointer-events: none;
  }

  .tutors--table--tab--select {
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    padding-bottom: 12px;
    width: 100%;
  }

  .tutor-managment-card {
    padding: 16px 0 0;
  }

  .btn {
    width: 100%;
  }

  .modal-button--deny {
    background: #D50303;
    color: white;
    border: none;
    margin-bottom: 12px;
  }

  textarea {
    width: 100%;
    height: 200px;
    border: 1px solid #EDEDED;
    border-radius: 4px;
    padding: 12px;
  }

  .mb-3 {
    margin-bottom: 8px;
  }
}

.sidebar {
  .mb-3 {
    margin-bottom: 8px;
  }

  textarea {
    width: 100%;
    height: 200px;
    border: 1px solid #EDEDED;
    border-radius: 4px;
    padding: 12px;
  }
}

.tutor-managment-head {
  border: none;
  justify-content: center;

  .absolute-left {
    position: absolute;
    left: calc(4px * 6);
  }

  .search-container {
    width: 100%;
    max-width: 448px;

    .search-icon {
      position: absolute;
      top: 9px;
      left: 8px;
    }
  }
}

.tutor-managment-card {
  padding: 32px 0 0;
}

.tutors--table--tab--select {
  padding: 0;
  justify-content: flex-start;
}

.tutors--table--tab {
  cursor: pointer;
  margin: 0 6px;
  padding: calc(#{$unit} * 2) calc(#{$unit} * 4);
  text-align: left;
  transition: all .2s ease;
  box-sizing: content-box;
  border-bottom: 2px solid transparent;

  &.active {
    border-bottom: 2px solid #7C6AF4;
    color: #7C6AF4;
  }
}

.tutors-table {
  width: 100%;
  border-collapse: collapse;

  tr {
    border: 1px solid #EDEDED;
    border-collapse: collapse;

    td {
      cursor: pointer;
      border: 1px solid #EDEDED;
      padding: 5px 10px;

      .react-select__flag {
        width: 25px;
        margin-right: 8px;
      }

      &.approve-deny {
        .btn--ghost {
          color: #D40303;
          border: 1px solid #D40303;
        }

        .btn {
          width: 50%;
        }
      }
    }

    &:first-of-type {
      border: none;

      td {
        border: none;
        padding: 12px 16px;
      }
    }

    &:nth-of-type(2n) {
      background: rgba(237, 237, 237, .3);
    }
  }

  .menu-container {
    display: flex;
    align-items: center;
    position: relative;
    border: none;

    .tutor-list-menu {
      position: absolute;
      top: 58px;
      left: -30px;
      width: 247px;
      display: flex;
      flex-direction: column;
      background: #FFF;
      border: 1px solid rgba(124, 106, 244, 0.5);
      box-sizing: border-box;
      box-shadow: 2px 4px 16px rgba(0, 0, 0, 0.05);
      border-radius: 5px;

      button {
        display: flex;

        i {
          margin-right: 12px;
        }

        &:first-of-type {
          i {
            background-color: #BDBDBD;
          }

          color: #BDBDBD;
        }

        &:last-of-type {
          i {
            background-color: #D50303;
          }

          color: #D50303;
        }
      }
    }
  }

  .dots {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 19px;
    margin-left: 90px;
    box-sizing: content-box;
    padding: 0 5px;

    span {
      display: block;
      border-radius: 100%;
      width: 5px;
      height: 5px;
      background: black;
      transition: all .2s ease;
    }

    &:hover {
      cursor: pointer;

      span {
        background: #7C6AF4;
      }
    }

    & + div {
      opacity: 0;
      z-index: -1;
      transition: .1s all .2s ease;
    }

    &:active + div,
    &:focus + div {
      opacity: 1;
      z-index: 2;
    }

  }

  &__item {
    display: flex;
    padding: calc(#{$unit} * 6);
    border: 1px solid $grey-ed;
    border-radius: $border-rounded;
    margin-bottom: calc(#{$unit} * 4);
    padding-right: calc(#{$unit} * 4);

    &:last-child {
      margin-bottom: 0;
    }

    &__img {
      width: $tutor-list-img-width;
      flex-shrink: 0;

      img {
        width: 154px;
        height: 154px;
        object-fit: cover;
        border-radius: $border-circle;
        border: 11px solid $color-primary-lighter;
      }
    }

    &__info {
      flex-grow: 1;
    }

    &__details {
      display: flex;
      flex-direction: column;
      width: $tutor-list-details-width;
      flex-shrink: 0;
      border-left: 1px solid $grey-ed;
      padding-left: calc(#{$unit} * 6);
    }
  }

  &__no-results {
    display: flex;
    flex-direction: column;
    align-items: center;

    &__title {
      margin-bottom: calc($unit * 4);
      font-size: 40px;
    }

    &__subtitle {
      margin-bottom: calc($unit * 10);
      color: $color-text-primary;
    }
  }
}

.bookings-table {
  width: 100%;
  border-collapse: collapse;

  tr {
    border: 1px solid #EDEDED;
    border-collapse: collapse;

    td {
      cursor: default;
      border: 1px solid #EDEDED;
      padding: 2px;

      .react-select__flag {
        width: 25px;
        margin-right: 8px;
      }

      &.approve-deny {
        .btn--ghost {
          color: #D40303;
          border: 1px solid #D40303;
        }

        .btn {
          width: 50%;
        }
      }
    }

    &:nth-of-type(2n) {
      background: rgba(237, 237, 237, .3);
    }
  }

  .menu-container {
    display: flex;
    align-items: center;
    position: relative;
    border: none;

    .tutor-list-menu {
      position: absolute;
      top: 58px;
      left: -30px;
      width: 247px;
      display: flex;
      flex-direction: column;
      background: #FFF;
      border: 1px solid rgba(124, 106, 244, 0.5);
      box-sizing: border-box;
      box-shadow: 2px 4px 16px rgba(0, 0, 0, 0.05);
      border-radius: 5px;

      button {
        display: flex;

        i {
          margin-right: 12px;
        }

        &:first-of-type {
          i {
            background-color: #BDBDBD;
          }

          color: #BDBDBD;
        }

        &:last-of-type {
          i {
            background-color: #D50303;
          }

          color: #D50303;
        }
      }
    }
  }

  .dots {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 19px;
    margin-left: 90px;
    box-sizing: content-box;
    padding: 0 5px;

    span {
      display: block;
      border-radius: 100%;
      width: 5px;
      height: 5px;
      background: black;
      transition: all .2s ease;
    }

    &:hover {
      cursor: pointer;

      span {
        background: #7C6AF4;
      }
    }

    & + div {
      opacity: 0;
      z-index: -1;
      transition: .1s all .2s ease;
    }

    &:active + div,
    &:focus + div {
      opacity: 1;
      z-index: 2;
    }

  }

  &__item {
    display: flex;
    padding: calc(#{$unit} * 6);
    border: 1px solid $grey-ed;
    border-radius: $border-rounded;
    margin-bottom: calc(#{$unit} * 4);
    padding-right: calc(#{$unit} * 4);

    &:last-child {
      margin-bottom: 0;
    }

    &__img {
      width: $tutor-list-img-width;
      flex-shrink: 0;

      img {
        width: 154px;
        height: 154px;
        object-fit: cover;
        border-radius: $border-circle;
        border: 11px solid $color-primary-lighter;
      }
    }

    &__info {
      flex-grow: 1;
    }

    &__details {
      display: flex;
      flex-direction: column;
      width: $tutor-list-details-width;
      flex-shrink: 0;
      border-left: 1px solid $grey-ed;
      padding-left: calc(#{$unit} * 6);
    }
  }

  &__no-results {
    display: flex;
    flex-direction: column;
    align-items: center;

    &__title {
      margin-bottom: calc($unit * 4);
      font-size: 40px;
    }

    &__subtitle {
      margin-bottom: calc($unit * 10);
      color: $color-text-primary;
    }
  }
}

.bookings-table thead th {
  position: sticky;
  top: 0;
  background-color: white; /* Or the color of your choice */
  z-index: 10;
}

.bookings-table tr {
  display: table;
  width: 100%;
  table-layout: fixed;
}

/* Fixed table header */
.tutors-table thead th {
  position: sticky;
  top: 0;
  background-color: white; /* Or the color of your choice */
  z-index: 10;
}

/* Scrollable tbody */
.table-scrollable-tbody {
  display: block;
  height: 600px;
  overflow-y: auto;
  overflow-x: hidden;
}

.student-table-scrollable-tbody {
  display: block;
  height: 80vh;
  overflow-y: auto;
  overflow-x: hidden;
}

/* Make table rows display in block */
.tutors-table tr {
  display: table;
  width: 100%;
  table-layout: fixed;
}


.no-border-row {
  border: none;

  td {
    border: none;
    padding: 12px 16px;
  }
}
