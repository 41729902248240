.educationItem {
    display: flex;
    flex-direction: column;
    gap: 8px;
    margin: 16px 0px 8px;
}

.bottomRow {
    display: grid;
    grid-template-columns: 1fr auto 1fr auto;
    grid-gap: 8px;
    align-items: center;
}
